import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
//import { useAuth } from '../authProvider'

// handle the public routes

interface PublicRouteProps extends RouteProps {
  component: any;
}

const PublicRoute = (props: PublicRouteProps) => {
  const { component: Component, ...rest } = props;
  //const [logged] = useAuth()
  return (
      <Route
          {...rest}
          render={(routeProps) =>
            !localStorage.getItem('isLogged') ? (
                  <Component {...routeProps} />
              ) : (
                      <Redirect
                          to={{
                              pathname: '/dashboard',
                              state: { from: routeProps.location }
                          }}
                      />
                  )
          }
      />
  )
}

export default PublicRoute