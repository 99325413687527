import React from 'react'
import { HashRouter as Router } from 'react-router-dom'
import { RouterSwitch } from "./Routes"

import 'startbootstrap-sb-admin-2/css/sb-admin-2.min.css'
//import 'mobx-react-lite/batchingOptOut'
//import 'mobx-react-lite/batchingForReactDom'
import { observer } from 'mobx-react'
//import withAppInsights from './AppInsights'

function App() {
  return (
    <div>      
      <Router>        
        <RouterSwitch />
      </Router>    
    </div>
  );
}
 
export default observer(App)